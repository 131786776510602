import { useCallback, useEffect, useState } from "react";
import { CardContainer } from ".";
import dayjs from "dayjs";
import { useQuery } from "react-query";

type MACHINE_STATE = "running" | "clean" | "dirty";

interface RemoteState {
  state: MACHINE_STATE
  lastState: MACHINE_STATE
  lastStateTs: string
}

const LABELS: { [key in MACHINE_STATE]: JSX.Element } = {
  "clean": <span className="badge bg-success">きれい</span>,
  "dirty": <span className="badge bg-danger">きたない</span>,
  "running": <span className="badge bg-secondary"><ruby>運転中<rp>(</rp><rt>うんてん中</rt><rp>)</rp></ruby></span>,
}

const WashingMachineCard: React.FC = () => {
  const { data } = useQuery("washingMachine", () => (
    fetch('/api/washingMachine').then(resp => resp.json()).then(resp => resp.data as RemoteState)
  ), {
    refetchInterval: 60_000, // refetch every 1 minute
  });

  const [ currentState, setCurrentStateVal ] = useState<MACHINE_STATE>("dirty");
  const [ lastState, setLastState ] = useState<MACHINE_STATE | undefined>();
  const [ lastStateTs, setLastStateTs ] = useState<dayjs.Dayjs | undefined>();

  const [ timeAgoStr, setTimeAgoStr ] = useState<string | undefined>();

  const setCurrentState = useCallback((state: MACHINE_STATE) => {
    setCurrentStateVal((oldState) => {
      setLastState(oldState);
      const ts = dayjs();
      setLastStateTs(ts);

      fetch('/api/washingMachine', {
        method: 'POST',
        body: JSON.stringify({
          state,
          lastState: oldState,
          lastStateTs: ts.toISOString(),
        }),
        headers: {
          'content-type': 'application/json'
        },
      });

      return state;
    });
  }, []);

  useEffect(() => {
    if (!lastState || !lastStateTs) return;

    let timer: number;

    const updater = () => {
      const minsAgo = dayjs().diff(lastStateTs, 'minutes');
      setTimeAgoStr(`${minsAgo}分前`);

      timer = window.setTimeout(updater, 1000);
    };
    updater();

    return () => {
      if (timer) window.clearTimeout(timer);
    }
  }, [lastState, lastStateTs]);

  useEffect(() => {
    if (!data) return;

    setCurrentStateVal(data.state);
    setLastState(data.lastState);
    setLastStateTs(dayjs(data.lastStateTs));
  }, [data]);

  return <CardContainer className="col col-sm-6">
    <div className="card-body">
      <h5 className="card-title"><ruby>洗濯機<rp>(</rp><rt>せんたくき</rt><rp>)</rp></ruby></h5>
      <p className="card-text fs-3">
        洗濯機は今 {LABELS[currentState]} です
      </p>
      { lastState && lastStateTs && <p className="card-text">
        <small>{timeAgoStr}、 {LABELS[lastState]} でした。</small>
      </p>}
      { currentState === "dirty"   && <button
        className="btn btn-primary"
        onClick={() => setCurrentState("running")}
      >
        <ruby>開始<rp>(</rp><rt>かいし</rt><rp>)</rp></ruby>した
      </button>}
      { currentState === "running" && <button
        className="btn btn-primary"
        onClick={() => setCurrentState("clean")}
      >
        終わった
      </button>}
      { currentState === "clean"   && <button
        className="btn btn-primary"
        onClick={() => setCurrentState("dirty")}
      >
        洗濯物を全部出した
      </button>}
    </div>
  </CardContainer>;
};

export default WashingMachineCard;
