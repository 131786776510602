interface CardContainerProps {
  className?: string
}

export const CardContainer: React.FC<React.PropsWithChildren<CardContainerProps>> = ({className, children}) => {
  return <div className={className || "col"}>
    <div className="card">
      {children}
    </div>
  </div>
};
